export const getContrastingColor = (color) => {
    color = color.replace('#', '');
    const red = parseInt(color.substr(0, 2), 16);
    const green = parseInt(color.substr(2, 2), 16);
    const blue = parseInt(color.substr(4, 2), 16);

    const brightness = (red * 299 + green * 587 + blue * 114) / 1000;
    if (brightness > 128) {
        return '#000000'; // Return black for bright colors
    } else {
        return '#FFFFFF'; // Return white for dark colors
    }
}

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const normalizeNumber = (value) => {
    return value.replace(/[^0-9]/g, '')
}

export const getUserRole = (user) => {
    const isCreator = user === 'Creator' || user === 'Admin';
    const isApprover = user === 'Approver' ;
    const isSuperAdmin = user === 'SuperAdmin'
    // const isAdmin = ;
    const access = isCreator ? {
        edit: true,
        delete: true,
        add: true,
        sendToApproval:  true,
        approve: false,
        decline: false,
    } : isApprover ? {
        edit: true,
        delete: false,
        add: false,
        sendToApproval: false,
        approve: true,
        decline: true,
    } : isSuperAdmin ? {
        edit: false,
        delete: false,
        add: false,
        sendToApproval: false,
        approve: true,
        decline: true,
        addUser: true
    } : {
        edit: true,
        delete: false,
        add: false,
        sendToApproval: false,
        approve: false,
        decline: false,
    }

    return { isCreator, isApprover, access, isSuperAdmin }
}