import { combineReducers } from "redux";
import { AuthReducer } from "./Auth/reducers";
import { DataReducer } from "./Data/reducers";

const rootReducers = combineReducers({
  Auth: AuthReducer,
  Data: DataReducer
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }
  return rootReducers(state, action);
};

export default rootReducer;