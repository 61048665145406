import React, { useState } from 'react'
import { Button, Container, Form } from 'react-bootstrap'
import { ContactFormWrap } from './style'
import PersonalForm from './PersonalForm';
import EducationForm from './EducationForm';
import AdditionalForm from './AdditionalForm';
import LocationForm from './LocationForm';
import { useDispatch, useSelector } from 'react-redux';
import UIModal from '../../Reuseable/RuModal';
import SettingsForm from './SettingsForm';
import CurrentStatusForm from './currentStatusForm';
import { usePatch } from '../../../Hooks/usePatch';
import { client } from '../../../App';
import { getUserRole } from '../../../Utilities/commonFn';
import { useOutletContext } from 'react-router-dom';
import { setAlertMessage } from '../../../redux/Data/actionCreator';
import { useFetch } from '../../../Hooks/useFetch';
import NotesForm from './NotesForm';

const initialForm = {
    personal: false,
    currentStatus: false,
    education: false,
    location: false,
    additional: false,
    settings: false,
    notes: false
}

const ContactForm = ({ contacts }) => {

    const user = useSelector((state) => state.Auth.user);
    const { isCreator, isApprover, isAdmin, access, isSuperAdmin } = getUserRole(user);
    const dispatch = useDispatch()
    const { setGlobalModal } = useOutletContext()
    const [declineReason, setDeclineReason] = useState('')
    const [editForm, setEditForm] = useState(initialForm);

    const activeContact = useSelector((state) => state.Data.activeContact);

    const contact = activeContact?.SK ? contacts?.data?.find((contact) => {
        return contact?.SK === activeContact?.SK;
    }) : activeContact

    const { data: selectedImage, isLoading: isImgLoading, isFetching: isImgFetching } = useFetch(`get-image-${contact?.SK}`, `/2-resource/profile.jpg/${contact?.SK}`,
        () => { }, () => { }, false, !client.getQueryData([`get-image-${contact?.SK}`])?.status && contact?.SK ? true : false)

    const setSelectedImage = (image) => {
        client.setQueryData([`get-image-${contact?.SK}`], (oldData) => {
            return {
                ...oldData,
                ...image
            }
        })
    }

    const handleModalClose = () => {
        setEditForm(initialForm)
    }

    const handleEditForm = (type) => {
        setEditForm({
            ...editForm,
            [type]: true
        })
    }

    const handleCloseForm = (type) => {
        setEditForm({
            ...editForm,
            [type]: false
        })
    }

    const { mutate: updateStatus } = usePatch('send-to-approval', `/2-resource`, (data) => {
        if (!data.status) return
        client.invalidateQueries(['contacts'])
        dispatch(setAlertMessage({
            message: data?.message,
            type: 'success',
        }))

    }, () => { })

    const handleDeclineReason = (e) => {
        setDeclineReason(e.target.value)
    }

    const confirmation = ({ title, content, reason = false }) => {

        return new Promise((resolve) => {
            setGlobalModal({
                title: title,
                content: <div className={`d-flex flex-column gap-3`}>
                    <h6>{content}</h6>

                    {reason && <div className='d-flex flex-column gap-1'>
                        <h5>Reason</h5>
                        <Form.Control as="textarea" rows={3} value={declineReason} onChange={handleDeclineReason} />
                    </div>}

                    <div className='d-flex justify-content-end gap-3'>
                        <Button variant='secondary' onClick={() => {
                            setGlobalModal({
                                show: false,
                                title: '',
                                content: null,
                            })
                            resolve(false)
                        }}>
                            Cancel
                        </Button>
                        <Button variant='primary' onClick={() => {
                            setGlobalModal({
                                show: false,
                                title: '',
                                content: null,
                            })
                            resolve(true)
                        }}>
                            Yes
                        </Button>
                    </div>
                </div>,
                show: true,
            })

        })
    }

    const handleSendToApproval = async () => {

        const approvalResult = await confirmation({
            title: 'Send to Approval',
            content: 'Are you sure you want to send this contact to approval?',
        })

        if (!approvalResult) {
            return
        }

        updateStatus({
            ID: contact?.SK,
            status: 'Pending' // Pending, Approved, Declined
        })
    }

    const handleDecline = async () => {

        setDeclineReason('')

        const declineReuslt = await confirmation({
            title: 'Decline',
            content: 'Are you sure you want to decline this contact?',
            reason: true,
        })

        if (!declineReuslt) {
            return
        }

        updateStatus({
            ID: contact?.SK,
            status: 'Declined', // Pending, Approved, Declined
            DeclinedReason: declineReason
        })
    }

    const handleApprove = async () => {

        const approveResult = await confirmation({
            title: 'Approve',
            content: 'Are you sure you want to approve this contact?',
        })

        if (!approveResult) {
            return
        }

        updateStatus({
            ID: contact?.SK,
            status: 'Approved' // Pending, Approved, Declined
        })
    }

    if (contacts?.data?.length === 0) {
        return <div className='w-100 d-flex justify-content-center'>
            <h4>No Data Found</h4>
        </div>
    }

    return (
        <ContactFormWrap>
            <Container>

                {contact?.Name && <div className='d-flex justify-content-between align-items-center'>
                    <h4 className="fw-bold my-3">{contact?.Name}</h4>               
                    <div>
                        {isCreator ? access.sendToApproval && contact?.Approval_Status !== "Approved" && <Button disabled={!contact?.ReadyForApproval} onClick={handleSendToApproval}>
                            Send to Approval
                        </Button> : null}
                        {(isApprover || (isSuperAdmin && contact?.Approval_Status !== "Approved")) ?
                            <div className='d-flex justify-content-center align-items-center gap-2'>
                                {access.decline && <Button onClick={handleDecline}>
                                    Decline
                                </Button>}

                                {access.approve && <Button variant='success' onClick={handleApprove}>
                                    Approve
                                </Button>}
                            </div>
                            : null}

                        {isAdmin && <></>}

                    </div>
                </div>}

                <PersonalForm selectedImage={selectedImage} isImgLoading={isImgLoading || isImgFetching}
                    setSelectedImage={setSelectedImage} contact={contact} handleEditForm={handleEditForm} />

                <UIModal show={editForm.personal} onHide={handleModalClose} size="xl" closeButton={false}>
                    <PersonalForm selectedImage={selectedImage} setSelectedImage={setSelectedImage} isImgLoading={isImgLoading || isImgFetching} contact={contact} type='edit' handleCloseForm={handleCloseForm} />
                </UIModal>

                <CurrentStatusForm contact={contact} handleEditForm={handleEditForm} />

                <UIModal show={editForm.currentStatus} onHide={handleModalClose} size="xl" closeButton={false}>
                    <CurrentStatusForm contact={contact} type='edit' handleCloseForm={handleCloseForm} />
                </UIModal>

                <EducationForm contact={contact} handleEditForm={handleEditForm} />

                <UIModal show={editForm.education} onHide={handleModalClose} size="xl" closeButton={false}>
                    <EducationForm contact={contact} type='edit' handleCloseForm={handleCloseForm} />
                </UIModal>

                <LocationForm contact={contact} handleEditForm={handleEditForm} />

                <UIModal show={editForm.location} onHide={handleModalClose} size="xl" closeButton={false}>
                    <LocationForm contact={contact} type='edit' handleCloseForm={handleCloseForm} />
                </UIModal>

                <AdditionalForm contact={contact} handleEditForm={handleEditForm} />

                <UIModal show={editForm.additional} onHide={handleModalClose} size="xl" closeButton={false}>
                    <AdditionalForm contact={contact} type='edit' handleCloseForm={handleCloseForm} />
                </UIModal>

                <SettingsForm contact={contact} handleEditForm={handleEditForm} />

                <UIModal show={editForm.settings} onHide={handleModalClose} size="xl" closeButton={false}>
                    <SettingsForm contact={contact} type='edit' handleCloseForm={handleCloseForm} />
                </UIModal>

                <NotesForm contact={contact} handleEditForm={handleEditForm} isApprover={isApprover || isSuperAdmin}/>

                <UIModal show={editForm.notes} onHide={handleModalClose} size="xl" closeButton={false}>
                    <NotesForm contact={contact} type='edit' handleCloseForm={handleCloseForm} />
                </UIModal>

            </Container>
        </ContactFormWrap>
    )
}

export default ContactForm

