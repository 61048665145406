import React, { useEffect } from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import { Section } from "./Section"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { usePatch } from "../../../Hooks/usePatch"
import { client } from "../../../App"
// import SelectBox from '../../Reuseable/RUSelectBox';
// import { yearOptions } from './AllSelectOptions';

const scheme = yup.object().shape({
  education: yup.array().of(
    yup.object().shape({
      institution: yup.string().required("Institution is required"),
      degree: yup.string().nullable(),
      year: yup.string().min(4, "Enter a Valid Year").max(4, "Enter a Valid Year").nullable(),
    })
  ),
})

const EducationForm = ({ contact, type, handleEditForm, handleCloseForm }) => {
  const [edit, setEdit] = React.useState(type === "edit" ? true : false)
  const formName = "education"

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm({
    resolver: yupResolver(scheme),
    defaultValues: {
      education: contact?.Education ?? [{ institution: null, degree: null, year: null }],
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: "education",
  })

  useEffect(() => {
    reset({
      education: contact?.Education ?? [{ institution: null, degree: null, year: null }],
    })
  }, [contact, reset])

  const handleEditShow = () => {
    reset()
    handleEditForm(formName)
    setEdit(true)
  }

  const handleEditClose = () => {
    handleCloseForm(formName)
    reset()
  }

  const onSuccess = async (data) => {
    if (!data.status) return
    await client.setQueryData(["contacts"], (oldData) => {
      return {
        status: data.status,
        data: oldData?.data?.map((contact) => {
          if (contact?.SK === data?.data?.SK) {
            return { ...contact, ...data?.data }
          }
          return contact
        }),
      }
    })
    handleCloseForm(formName)
  }

  const { mutate, isLoading } = usePatch(
    "update-contact",
    `/2-resource/${contact?.SK}`,
    onSuccess,
    () => {}
  )

  const onSubmit = (data) => {
    const educationData = data.education.map((edu) => ({
      institution: edu.institution,
      degree: edu.degree,
      year: edu.year,
    }))

    mutate({ Education: educationData })
  }

  const handleAddMore = () => {
    append({ institution: null, degree: null, year: null })
  }

  const update = edit && type === "edit"

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Section
        title='Education'
        onShowEdit={handleEditShow}
        onCloseEdit={handleEditClose}
        isLoading={isLoading}
        type={type}
        disableUpdate={isDirty}
        edit={edit}
        isApproved={contact?.Approval_Status === "Approved"}
      >
        {fields.map((field, index) => (
          <Row key={field.id}>
            <Col md={4}>
              {update ? (
                <Form.Group controlId={`education[${index}].institution`}>
                  <Form.Label>Institution</Form.Label>
                  <Controller
                    name={`education[${index}].institution`}
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                      <Form.Control placeholder='Enter Institution' type='text' {...field} />
                    )}
                  />
                  {errors.education && errors.education[index]?.institution && (
                    <Form.Control.Feedback type='invalid'>
                      {errors.education[index]?.institution.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              ) : (
                <p>Institution: {field.institution ? field.institution : "None"}</p>
              )}
            </Col>
            <Col md={4}>
              {update ? (
                <Form.Group controlId={`education[${index}].degree`}>
                  <Form.Label>Degree</Form.Label>
                  <Controller
                    name={`education[${index}].degree`}
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                      <Form.Control placeholder='Enter Degree' type='text' {...field} />
                    )}
                  />
                  {errors.education && errors.education[index]?.degree && (
                    <Form.Control.Feedback type='invalid'>
                      {errors.education[index]?.degree.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              ) : (
                <p>Degree: {field.degree ? field.degree : "None"}</p>
              )}
            </Col>
            <Col md={index === 0 ? 4 : 2}>
              {update ? (
                <Form.Group controlId={`education[${index}].year`}>
                  <Form.Label>Year</Form.Label>
                  <Controller
                    name={`education[${index}].year`}
                    control={control}
                    render={({ field }) => (
                      <Form.Control placeholder='Enter Institution' type='text' {...field} />
                    )}
                  />
                  {errors.education && errors.education[index]?.year && (
                    <Form.Control.Feedback type='invalid'>
                      {errors.education[index]?.year.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              ) : (
                <p>Year: {field?.year ? field?.year : "None"}</p>
              )}
            </Col>

            {index !== 0 && update && (
              <Col md={index === 0 ? 4 : 2}>
                <div className='remove_button_area'>
                  <Button type='button' onClick={() => remove(index)}>
                    Remove
                  </Button>
                </div>
              </Col>
            )}
          </Row>
        ))}

        {update && (
          <div className='d-flex justify-content-center mt-2'>
            <Button type='button' onClick={handleAddMore}>
              Add More
            </Button>
          </div>
        )}
      </Section>
    </Form>
  )
}

export default EducationForm
