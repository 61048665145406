import { useMutation } from "@tanstack/react-query"
import { api } from "../Services"

export const usePatch = (queryKey, url, onSuccess, onError, select) => {

    return useMutation(
        [queryKey],
        async (data) => {
            return await api.patch(
                url, data, {
                headers: {
                    authorizationToken: localStorage.getItem("token"),
                },
            }
            )
                .then((res) => {
                    return res.data
                })
        },
        {
            onSuccess,
            onError,
            select: select
        })
}