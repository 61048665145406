import React, { useEffect } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { Section } from './Section'
import { usePatch } from '../../../Hooks/usePatch';
import { client } from '../../../App';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup'
import SelectBox from '../../Reuseable/RUSelectBox';
import { statusOptions, studentStatusOptions } from './AllSelectOptions';

const schema = yup.object().shape({
    status: yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
    }).required(),
    JobTitle: yup.string(),
    Employer: yup.string(),
    UnemploymentReason: yup.string(),
    SelfEmployedDescription: yup.string(),
    RetirementDate: yup.string(),
    StudentStatus: yup.object().shape({
        label: yup.string(),
        value: yup.string(),
    }).nullable(),
    OtherStatusDescription: yup.string(),
})

const CurrentStatusForm = ({ contact, type, handleEditForm, handleCloseForm, }) => {

    const [edit, setEdit] = React.useState(type === 'edit' ? true : false);
    const formName = "currentStatus"

    const { register, handleSubmit, formState: { errors, isDirty }, reset, control, watch } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            status: contact?.Status ?? null,
            jobTitle: contact?.JobTitle ?? null,
            employer: contact?.Employer ?? null,
            unemploymentReason: contact?.UnemploymentReason ?? null,
            selfEmployedDescription: contact?.SelfEmployedDescription ?? null,
            retirementDate: contact?.RetirementDate ?? null,
            studentStatus: contact?.StudentStatus ?? null,
            otherStatusDescription: contact?.OtherStatusDescription ?? null,
        }
    });

    useEffect(() => {
        reset({
            status: contact?.Status ?? null,
            jobTitle: contact?.JobTitle ?? null,
            employer: contact?.Employer ?? null,
            unemploymentReason: contact?.UnemploymentReason ?? null,
            selfEmployedDescription: contact?.SelfEmployedDescription ?? null,
            retirementDate: contact?.RetirementDate ?? null,
            studentStatus: contact?.StudentStatus ?? null,
            otherStatusDescription: contact?.OtherStatusDescription ?? null,
        })
    }, [contact, reset])

    const status = watch("status")

    const handleEditShow = () => {
        reset()
        handleEditForm(formName)
        setEdit(true)
    }

    const handleEditClose = () => {
        handleCloseForm(formName)
        reset()
    }

    const onSuccess = async (data) => {
        if (!data.status) return
        await client.setQueryData(["contacts"], (oldData) => {
            return {
                status: data.status,
                data: oldData?.data?.map((contact) => {
                    if (contact?.SK === data?.data?.SK) {
                        return { ...contact, ...data?.data }
                    }
                    return contact
                })
            }
        }
        )
        handleCloseForm(formName)
    }

    const { mutate, isLoading } = usePatch("update-contact", `/2-resource/${contact?.SK}`, onSuccess, () => { })

    const onSubmit = (e) => {
        let data = {
            Status: e.status,
            JobTitle: e.jobTitle,
            Employer: e.employer,
            UnemploymentReason: e.unemploymentReason,
            SelfEmployedDescription: e.selfEmployedDescription,
            RetirementDate: e.retirementDate,
            StudentStatus: e.studentStatus,
            OtherStatusDescription: e.otherStatusDescription,
        }
        mutate(data)
    }

    const update = (edit && (type === "edit")) ? true : false

    const isEmployed = (status?.label === 'Employed' || contact?.Status?.label === 'Employed') ? true : false

    const isUnEmployed = (status?.label === 'Unemployed' || contact?.Status?.label === 'Unemployed') ? true : false

    const isSelfEmployed = (status?.label === 'Self-employed' || contact?.Status?.label === 'Self-employed') ? true : false

    const isRetired = (status?.label === 'Retired' || contact?.Status?.label === 'Retired') ? true : false

    const isStudent = (status?.label === 'Student' || contact?.Status?.label === 'Student') ? true : false

    const isOther = (status?.label === 'Other' || contact?.Status?.label === 'Other') ? true : false

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Section title="Current Status"
                onShowEdit={handleEditShow}
                onCloseEdit={handleEditClose}
                isLoading={isLoading}
                type={type}
                disableUpdate={isDirty}
                edit={edit}
                isApproved={contact?.Approval_Status === "Approved"}>
                <Row>
                    <Col md={4}>
                        {update ?
                            <Form.Group controlId="status">
                                <Form.Label>Status</Form.Label>
                                <Controller
                                    ref={register}
                                    name="status"
                                    control={control}
                                    render={({ field }) => (
                                        <SelectBox {...field} options={statusOptions} />
                                    )}
                                />
                                {errors?.status?.message && <Form.Control.Feedback type="invalid">
                                    {errors.status?.message}
                                </Form.Control.Feedback>}
                            </Form.Group> :
                            <p>Status: {contact?.Status?.label ? contact?.Status?.label : "None"}</p>}
                    </Col>


                    {isEmployed && (
                        <Col md={4}>
                            {update ? <Form.Group>
                                <Form.Label>Job Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    {...register("jobTitle")}
                                    placeholder='Enter Job Title'
                                />

                                {errors.jobTitle?.message && <Form.Control.Feedback type="invalid">
                                    {errors.jobTitle?.message}
                                </Form.Control.Feedback>}

                            </Form.Group> : <p>Job Title: {contact?.JobTitle ? contact?.JobTitle : "None"}</p>}
                        </Col>
                    )}

                    {isEmployed && (
                        <Col md={4}>
                            {update ? <Form.Group>
                                <Form.Label>Employer</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder='Enter Employer'
                                    {...register("employer")}
                                />
                                {errors.employer?.message && <Form.Control.Feedback type="invalid">
                                    {errors.employer?.message}
                                </Form.Control.Feedback>}
                            </Form.Group> : <p>Employer: {contact?.Employer ? contact?.Employer : "None"}</p>}
                        </Col>
                    )}

                    {isUnEmployed && (
                        <Col md={4}>
                            {update ? <Form.Group>
                                <Form.Label>Reason for Unemployment</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder='Enter Reason for Unemployment'
                                    {...register("unemploymentReason")}
                                />
                                {errors.unemploymentReason?.message && <Form.Control.Feedback type="invalid">
                                    {errors.unemploymentReason?.message}
                                </Form.Control.Feedback>}
                            </Form.Group> : <p>Reason for Unemployment: {contact?.UnemploymentReason ? contact?.UnemploymentReason : "None"}</p>}
                        </Col>
                    )}

                    {isSelfEmployed && (
                        <Col md={12}>
                            {update ? <Form.Group>
                                <Form.Label>Self-employed Description</Form.Label>
                                <Form.Control
                                    type="text"
                                    as="textarea"
                                    rows={3}
                                    {...register("selfEmployedDescription")}
                                />
                                {errors.selfEmployedDescription?.message && <Form.Control.Feedback type="invalid">
                                    {errors.selfEmployedDescription?.message}
                                </Form.Control.Feedback>}
                            </Form.Group> : <p>Self-employed Description: {contact?.SelfEmployedDescription ? contact?.SelfEmployedDescription : "None"}</p>}
                        </Col>
                    )}

                    {isRetired && (
                        <Col md={4}>
                            {update ? <Form.Group>
                                <Form.Label>Retirement Date</Form.Label>
                                <Form.Control
                                    placeholder='Enter Retirement Date'
                                    type="date"
                                    {...register("retirementDate")}
                                />
                                {errors.retirementDate?.message && <Form.Control.Feedback type="invalid">
                                    {errors.retirementDate?.message}
                                </Form.Control.Feedback>}
                            </Form.Group> : <p>Retirement Date: {contact?.RetirementDate ? contact?.RetirementDate : "None"}</p>}
                        </Col>
                    )}

                    {isStudent && (
                        <Col md={4}>
                            {update ? <Form.Group>
                                <Form.Label>Student Status</Form.Label>
                                <Controller
                                    ref={register}
                                    name="studentStatus"
                                    control={control}
                                    render={({ field }) => (
                                        <SelectBox {...field} options={studentStatusOptions} />
                                    )}
                                />
                                {errors.studentStatus?.message && <Form.Control.Feedback type="invalid">
                                    {errors.studentStatus?.message}
                                </Form.Control.Feedback>}
                            </Form.Group> : <p>Student Status: {contact?.StudentStatus?.label ? contact?.StudentStatus?.label : "None"}</p>}
                        </Col>
                    )}

                    {isOther && (
                        <Col md={12}>
                            {update ? <Form.Group>
                                <Form.Label>Other Status Description</Form.Label>
                                <Form.Control
                                    type="text"
                                    as="textarea"
                                    rows={3}
                                    {...register("otherStatusDescription")}
                                />
                                {errors.otherStatusDescription?.message && <Form.Control.Feedback type="invalid">
                                    {errors.otherStatusDescription?.message}
                                </Form.Control.Feedback>}
                            </Form.Group> : <p>Other Status Description: {contact?.OtherStatusDescription ? contact?.OtherStatusDescription : "None"}</p>}
                        </Col>
                    )}

                </Row>


            </Section>
        </Form>
    )
}

export default CurrentStatusForm