import React from 'react'
import Select from 'react-select'

const selectStyles = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        outline: "none",
        borderColor: "#ced4da",
    }),
};

const SelectBox = React.forwardRef((props, ref) => (<Select ref={ref} styles={selectStyles} isClearable={true} {...props} />));

export default SelectBox

