import { allCountries } from "../../../Data/countryName"
import allCurrency from "../../../Data/currency.json"

const arrayGenerator = (start, end) => {
    let arr = []
    for (let i = start; i <= end; i++) {
        arr.push({ value: i, label: i })
    }
    return arr
}

export const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "other", label: "Other" },
]

export const skinColorOptions = [
    { value: "#8d5524", label: "Brown" },
    { value: "#c68642", label: "Light Brown" },
    { value: "#e0ac69", label: "Light" },
    { value: "#f1c27d", label: "Fair" },
    { value: "#ffdbac", label: "Very Fair" },
    { value: "#ffffff", label: "White" },
    { value: "#000000", label: "Black" },
]

export const weightOptions = arrayGenerator(10, 150)

export const heightOptions = [
    { value: "2'0", label: "2'0" },
    { value: "2'5", label: "2'5" },
    { value: "2'10", label: "2'10" },
    { value: "3'0", label: "3'0" },
    { value: "3'5", label: "3'5" },
    { value: "3'10", label: "3'10" },
    { value: "4'0", label: "4'0" },
    { value: "4'5", label: "4'5" },
    { value: "4'10", label: "4'10" },
    { value: "5'0", label: "5'0" },
    { value: "5'5", label: "5'5" },
    { value: "5'10", label: "5'10" },
    { value: "6'0", label: "6'0" },
    { value: "6'5", label: "6'5" },
    { value: "6'10", label: "6'10" },
    { value: "7'0", label: "7'0" },
    { value: "7'5", label: "7'5" },
]

export const religionOptions = [
    { value: "hindu", label: "Hindu" },
    { value: "muslim", label: "Muslim" },
    { value: "christian", label: "Christian" },
    { value: "sikh", label: "Sikh" },
    { value: "jain", label: "Jain" },
    { value: "buddhist", label: "Buddhist" },
    { value: "other", label: "Other" },
]

export const countryOptions = allCountries.map((country) => {
    return { value: country.code, label: country.name }
})

export const currencyOptions = Object.keys(allCurrency).map((key) => {
    return { value: key, label: allCurrency[key].name }
})

export const favColorOptions = [
    { value: "#ff0000", label: "Red" },
    { value: "#ffa500", label: "Orange" },
    { value: "#ffff00", label: "Yellow" },
    { value: "#008000", label: "Green" },
    { value: "#0000ff", label: "Blue" },
    { value: "#4b0082", label: "Indigo" },
    { value: "#ee82ee", label: "Violet" },
    { value: "#000000", label: "Black" },
    { value: "#ffffff", label: "White" },
    { value: "#808080", label: "Gray" },
    { value: "#800000", label: "Maroon" },
    { value: "#808000", label: "Olive" },
    { value: "#008080", label: "Teal" },
    { value: "#800080", label: "Purple" },
    { value: "#f0f8ff", label: "AliceBlue" },
    { value: "#faebd7", label: "AntiqueWhite" },
    { value: "#00ffff", label: "Aqua" },
    { value: "#7fffd4", label: "Aquamarine" }
]

export const employmentTypeOptions = [
    { value: "fulltime", label: "Full Time" },
    { value: "parttime", label: "Part Time" },
    { value: "contract", label: "Contract" },
    { value: "internship", label: "Internship" },
    { value: "volunteer", label: "Volunteer" },
    { value: "other", label: "Other" },
]

export const maritialStatusOptions = [
    { value: "single", label: "Single" },
    { value: "married", label: "Married" },
    { value: "divorced", label: "Divorced" },
    { value: "widowed", label: "Widowed" },
]

export const visibilityOptions = [
    { value: "public", label: "Public" },
    { value: "private", label: "Private" },
    { value: "onlyme", label: "Only Me" },
]

export const yearOptions = arrayGenerator(1950, new Date().getFullYear())

export const statusOptions = [
    { value: "employed", label: "Employed" },
    { value: "unemployed", label: "Unemployed" },
    { value: "self-employed", label: "Self-employed" },
    { value: "retired", label: "Retired" },
    { value: "student", label: "Student" },
    { value: "other", label: "Other" },
]

export const studentStatusOptions = [
    { value: "school", label: "School" },
    { value: "highschool", label: "High School" },
    { value: "diploma", label: "Diploma" },
    { value: "college", label: "College" },
    { value: "university", label: "University" },
    { value: "other", label: "Other" },
]