import React from "react"
import { useSelector } from "react-redux"
import LayoutWrap from "./style"
import { Outlet } from "react-router-dom"
import UIHeader from "../../../Reuseable/RuHeader"
import UIModal from "../../../Reuseable/RuModal"

const Main = () => {
	const layout = useSelector((state) => state.Auth.layout)

	const [globalModal, setGlobalModal] = React.useState({
		show: false,
		title: "",
		content: "",
	})

	return (
		<LayoutWrap>
			<div className="maincontainer-scroller">
				{layout.header.show && <UIHeader
				/>}
				<div className="container-fluid page-body-wrapper">

					{layout.main.show && <div className="main-panel">
						<div className="content-wrapper">
							<Outlet
								context={{ setGlobalModal }}
							/>

							<UIModal show={globalModal.show} onHide={() => setGlobalModal((prev) => {
								return { ...prev, show: false }
							})}
								closeButton={true}
								title={globalModal.title}
							>

								{globalModal.content}

							</UIModal>

						</div>
					</div>}
				</div>
			</div>
		</LayoutWrap>
	)
}

export default Main
