import React, { useEffect } from "react";
import LoginWrap from "./style";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoginForm from "./LoginForm";

const Login = ({ props, isFirstTimeLoggin }) => {
  const isLoggedIn = useSelector(state => state.Auth.isLoggedIn)
  const navigate = useNavigate()
  const layout = useSelector((state) => state.Auth.layout)

  const { login } = layout

  useEffect(() => {
    isLoggedIn && navigate('/home/dashBoard')
  }, [isLoggedIn, navigate])

  return (
    <LoginWrap position={login?.loginFormPosition} backgroundColor={login?.loginBackground} textColor={login?.loginTextColor} backgroundImage={login?.backgroundImage}>
      <section className="loginPageContent" >
        <div className="container-width">
          <LoginForm props={props} isFirstTimeLoggin={isFirstTimeLoggin} />
        </div>
      </section>
    </LoginWrap>
  );
};

export default Login;
