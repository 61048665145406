import { toast } from "react-toastify";
import { ActionTypes } from "./actions";

const intialState = {
    activeContact: {},
    alertMessage: '',
};

export const DataReducer = (state = intialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_ACTIVE_CONTACT:
            return {
                ...state,
                activeContact: payload
            }
        case ActionTypes.SET_ALERT_MESSAGE:
            toast(payload.message, {
                type: payload.type,
                position: "bottom-right"
            })
            return {
                ...state,
                alertMessage: payload
            }


        default:
            return state;
    }
}