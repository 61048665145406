import React, { useRef } from "react"
import Cropper from "react-cropper"
import "cropperjs/dist/cropper.css"
import { Button } from "react-bootstrap"

export const ImageCropper = ({ image, contact, setSelectedImage, setCropImage }) => {
  const cropperRef = useRef()

  const getCropData = (type) => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const croppedImg = cropperRef.current?.cropper?.getCroppedCanvas().toDataURL()
      setSelectedImage({
        URL: croppedImg,
        ID: contact?.SK,
        fileName: "profile.jpg",
        fileType: croppedImg.split(":")[1].split(";")[0],
        codeValue: croppedImg.split(",")[1],
      })
      type === "save" && setCropImage(false)
    }
  }

  return (
    <div>
      <Cropper
        ref={cropperRef}
        style={{ height: 400, width: "100%" }}
        zoomTo={0.5}
        initialAspectRatio={1}
        preview='.img-preview'
        src={image}
        viewMode={1}
        minCropBoxHeight={10}
        minCropBoxWidth={10}
        background={false}
        responsive={true}
        autoCropArea={1}
        checkOrientation={false}
        aspectRatio={1}
        guides={true}
      />
      <div className='d-flex justify-content-end mt-2 gap-3'>
        <Button onClick={getCropData}>Crop Image</Button>
        <Button onClick={() => getCropData("save")}>Save</Button>
      </div>
    </div>
  )
}

export default ImageCropper
