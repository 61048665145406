import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import HeaderWrap from "./style";
import logoutIcon from "../../../assets/images/icons/logoutB.svg";
import { userLogout } from "../../../redux/Auth/actionCreator";
import BurgerMenuButton from "../RuAnimationButton";
import marketingTool from "../../../assets/images/icons/MarketingTool.png";
import { api } from "../../../Services";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { setActiveContact } from "../../../redux/Data/actionCreator";
import jwtDecode from "jwt-decode";
import AddUserForm from "../../Pages/AddUserForm";
import UIModal from "../RuModal";


const UIHeader = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [addUserModal, setAddUserModal] = React.useState(false);
  const userRole = localStorage.getItem("token")
    ? jwtDecode(localStorage.getItem("token"))?.userRole
    : "";
  const userId = localStorage.getItem("token")
    ? jwtDecode(localStorage.getItem("token"))?.userId
    : "";
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const layout = useSelector((state) => state.Auth.layout);

  const { header } = layout;

  const navlink = [];

  const postLogout = async () => {
    try {
      const response = await api.post(
        "/broker-auth/logout",
        {},
        {
          headers: {
            authorizationToken: localStorage.getItem("token"),
          },
        }
      );
      console.log("[postLogout] response-->", response);
      response.data.logout && navigate("/login");
    } catch (error) {
      console.log("[postLogout] error-->", error.response);
    }
  };

  let navigate = useNavigate();
  const logoutUser = () => {
    postLogout();
    dispatch(userLogout());
  };
  const handleLogoClick = () => {
    dispatch(setActiveContact({}));
    navigate("/home/dashBoard");
    return;
  };

  const handleModalClose = () => {
    setAddUserModal(false);
  };

  return (
    <HeaderWrap>
      <nav
        className={`navbar navbar-expand-md navbar-light bg-${header.background} shadow position-fixed w-100 z-index-navbar`}
      >
        <div className="container-fluid pe-0">
          <div className="navbar-item">
            {header.logo.show && (
              <div>
                <div onClick={handleLogoClick}>
                  <img className="header_logo" src={marketingTool} alt="logo" />
                </div>
              </div>
            )}
            <div className="role">
              {userRole === "SuperAdmin" && <button onClick={() => setAddUserModal(true)}>Add User</button>}
              <div className="subrole">
                <p>Role : {userRole}</p>
                <p>Email : {userId}</p>
              </div>
            </div>
            <div className="menu-display">
              <div className="hide-menu">
                <div>
                  <div
                    className="border-0 p-0 btn"
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <BurgerMenuButton open={open} />
                  </div>
                  <Menu
                    id="basic-menu"
                    className="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <div className="position-relative">
                      {navlink?.map((nav) => {
                        return (
                          <MenuItem
                            key={nav?.navLink}
                            className="fw-bold blue-theme"
                            onClick={handleClose}
                          >
                            <NavLink
                              className="fw-bold blue-theme"
                              to={nav?.navLink}
                            >
                              <div className="menu-item">{nav?.item}</div>
                            </NavLink>
                          </MenuItem>
                        );
                      })}
                      {header.logout && (
                        <MenuItem
                          className="fw-bold blue-theme "
                          to="#"
                          type="button"
                          onClick={logoutUser}
                          title="Logout"
                        >
                          <div className="menu-item">Logout</div>
                        </MenuItem>
                      )}
                    </div>
                  </Menu>
                </div>
              </div>
            </div>
          </div>

          <div
            className="collapse navbar-collapse justify-content-start"
            id="navbarNavAltMarkup"
          >
            <ul className="navbar-nav">
              {header.logout && (
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="#"
                    type="button"
                    onClick={logoutUser}
                    title="Logout"
                  >
                    <div className="profileLetter d-flex justify-content-center align-items-center">
                      <img
                        src={logoutIcon}
                        height={29}
                        width={29}
                        alt="Logout"
                      ></img>
                    </div>
                  </Link>
                </li>
              )}

              {navlink?.map((nav, index) => {
                return (
                  index + 1 <= header.menutoshow && (
                    <li key={nav?.item + index} className="nav-item mx-2">
                      <NavLink
                        className={`navbar-brand nav-link ${
                          header.background === "black"
                            ? "text-white"
                            : "text-black"
                        }`}
                        to={nav?.navLink}
                        title={nav?.item}
                      >
                        {nav?.item}
                      </NavLink>
                    </li>
                  )
                );
              })}
            </ul>
          </div>
        </div>
      </nav>
      <UIModal
        show={addUserModal}
        onHide={handleModalClose}
        size="lg"
        closeButton={true}
      >
        <AddUserForm handleCloseModal={handleModalClose}/>
      </UIModal>
    </HeaderWrap>
  );
};
export default UIHeader;
