import React from "react"
import { Col, Form, Row } from "react-bootstrap"
import { Section } from "./Section"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import SelectBox from "../../Reuseable/RUSelectBox"
import { client } from "../../../App"
import { usePatch } from "../../../Hooks/usePatch"
import { getContrastingColor, normalizeNumber } from "../../../Utilities/commonFn"
import { favColorOptions } from "./AllSelectOptions"

const formatOptionLabel = ({ label, value }) => {
  return (
    <div style={{ display: "flex" }}>
      <div style={{ background: value, color: getContrastingColor(value) }}>{label}</div>
    </div>
  )
}

const schema = yup.object().shape({
  hobbies: yup.string().nullable(),
  medicalIssue: yup.string().nullable(),
  physicallyChallenged: yup.boolean().nullable(),
  physicalChallenges: yup.string().when("physicallyChallenged", {
    is: true,
    then: (schema) => schema.nullable(),
    otherwise: (schema) => schema.notRequired(),
  }),
  favoriteColor: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string(),
    })
    .nullable(),
  longTermGoal: yup.string().nullable(),
  shortTermGoal: yup.string().nullable(),
  passion: yup.string().nullable(),
  workPreference: yup.string().nullable(),
  expectedSalary: yup.string().nullable(),
})

const AdditionalForm = ({ contact, type, handleEditForm, handleCloseForm }) => {
  const [edit, setEdit] = React.useState(type === "edit" ? true : false)
  const formName = "additional"

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    control,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      hobbies: contact?.Hobbies ?? null,
      medicalIssue: contact?.MedicalIssue ?? null,
      physicallyChallenged: contact?.PhysicallyChallenged ?? null,
      physicalChallenges: contact?.PhysicalChallenges ?? null,
      favoriteColor: contact?.FavoriteColor ?? null,
      roleModal: contact?.RoleModal ?? null,
      lifeExpectation: contact?.LifeExpectation ?? null,
      longTermGoal: contact?.LongTermGoal ?? null,
      shortTermGoal: contact?.ShortTermGoal ?? null,
      passion: contact?.Passion ?? null,
      workPreference: contact?.WorkPreference ?? null,
      expectedSalary: contact?.ExpectedSalary ?? null,
      employmentType: contact?.EmploymentType ?? null,
    },
  })

  React.useEffect(() => {
    reset({
      hobbies: contact?.Hobbies ?? null,
      medicalIssue: contact?.MedicalIssue ?? null,
      physicallyChallenged: contact?.PhysicallyChallenged ?? null,
      physicalChallenges: contact?.PhysicalChallenges ?? null,
      favoriteColor: contact?.FavoriteColor ?? null,
      roleModal: contact?.RoleModal ?? null,
      lifeExpectation: contact?.LifeExpectation ?? null,
      longTermGoal: contact?.LongTermGoal ?? null,
      shortTermGoal: contact?.ShortTermGoal ?? null,
      passion: contact?.Passion ?? null,
      workPreference: contact?.WorkPreference ?? null,
      expectedSalary: contact?.ExpectedSalary ?? null,
      employmentType: contact?.EmploymentType ?? null,
    })
  }, [contact, reset])

  const isPhysicallyChallenged = watch("physicallyChallenged")

  const handleEditShow = () => {
    reset()
    handleEditForm(formName)
    setEdit(true)
  }

  const handleEditClose = () => {
    handleCloseForm(formName)
    reset()
  }

  const onSuccess = async (data) => {
    if (!data.status) return
    await client.setQueryData(["contacts"], (oldData) => {
      return {
        status: data.status,
        data: oldData?.data?.map((contact) => {
          if (contact?.SK === data?.data?.SK) {
            return { ...contact, ...data?.data }
          }
          return contact
        }),
      }
    })
    handleCloseForm(formName)
  }

  const { mutate, isLoading } = usePatch(
    "update-contact",
    `/2-resource/${contact?.SK}`,
    onSuccess,
    () => {}
  )

  const onSubmit = (e) => {
    let data = {
      Hobbies: e?.hobbies,
      MaritalStatus: e?.maritalStatus,
      MedicalIssue: e?.medicalIssue,
      LifeExpectation: e?.lifeExpectation,
      PhysicallyChallenged: e?.physicallyChallenged,
      PhysicalChallenges: e?.physicalChallenges,
      FavoriteColor: e?.favoriteColor,
      LongTermGoal: e?.longTermGoal,
      ShortTermGoal: e?.shortTermGoal,
      Passion: e?.passion,
      WorkPreference: e?.workPreference,
      ExpectedSalary: e?.expectedSalary,
    }
    mutate(data)
  }

  const update = edit && type === "edit" ? true : false

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Section
        title='Additional'
        onShowEdit={handleEditShow}
        onCloseEdit={handleEditClose}
        isLoading={isLoading}
        type={type}
        disableUpdate={isDirty}
        edit={edit}
        isApproved={contact?.Approval_Status === "Approved"}
      >
        <Row>
          <Col md={12}>
            {update ? (
              <Form.Group controlId='hobbies'>
                <Form.Label>Hobbies</Form.Label>
                <Form.Control
                  {...register("hobbies")}
                  as='textarea'
                  type='text'
                  placeholder='Enter hobbies'
                />
                {errors.hobbies && (
                  <Form.Control.Feedback type='invalid'>
                    {errors.hobbies.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            ) : (
              <p>Hobbies : {contact?.Hobbies ? contact?.Hobbies : "None"}</p>
            )}
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            {update ? (
              <Form.Group controlId='favoriteColor'>
                <Form.Label>Favorite Color</Form.Label>

                <Controller
                  ref={register}
                  name='favoriteColor'
                  control={control}
                  render={({ field }) => (
                    <SelectBox
                      formatOptionLabel={formatOptionLabel}
                      options={favColorOptions}
                      {...field}
                    />
                  )}
                />

                {errors.favoriteColor && (
                  <Form.Control.Feedback type='invalid'>
                    {errors.favoriteColor.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            ) : (
              <p>
                Favorite Color:{" "}
                {contact?.FavoriteColor?.label ? contact?.FavoriteColor?.label : "None"}
              </p>
            )}
          </Col>

          <Col md={4}>
            {update ? (
              <Form.Group controlId='medicalIssue'>
                <Form.Label>Medical Issue</Form.Label>
                <Form.Control
                  {...register("medicalIssue")}
                  type='text'
                  placeholder='Enter medical issue'
                />
                {errors.medicalIssue && (
                  <Form.Control.Feedback type='invalid'>
                    {errors.medicalIssue.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            ) : (
              <p>Medical Issue: {contact?.MedicalIssue ? contact?.MedicalIssue : "None"}</p>
            )}
          </Col>

          <Col md={4}>
            {update ? (
              <Form.Group controlId='lifeExpectation'>
                <Form.Label>Your life's ambition</Form.Label>
                <Form.Control
                  {...register("lifeExpectation")}
                  type='text'
                  placeholder='Enter life expectation'
                />
                {errors.lifeExpectation && (
                  <Form.Control.Feedback type='invalid'>
                    {errors.lifeExpectation.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            ) : (
              <p>
                Your life's ambition: {contact?.LifeExpectation ? contact?.LifeExpectation : "None"}
              </p>
            )}
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            {update ? (
              <Form.Group controlId='physicallyChallenged'>
                <Form.Label>Physically Challenged</Form.Label>

                <Form.Check
                  {...register("physicallyChallenged")}
                  type='checkbox'
                  label='Yes'
                  className='checkbox-custom'
                />
                {errors.physicallyChallenged && (
                  <Form.Control.Feedback type='invalid'>
                    {errors.physicallyChallenged.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            ) : (
              <p>Physically Challenged: {contact?.PhysicallyChallenged ? "Yes" : "No"}</p>
            )}
          </Col>

          {(isPhysicallyChallenged || contact?.PhysicallyChallenged) && (
            <Col md={8}>
              {update ? (
                <Form.Group controlId='physicalChallenges'>
                  <Form.Label>Physical Challenges</Form.Label>
                  <Form.Control
                    {...register("physicalChallenges")}
                    type='text'
                    as='textarea'
                    placeholder='Enter physical challenges'
                  />
                  {errors.physicalChallenges && (
                    <Form.Control.Feedback type='invalid'>
                      {errors.physicalChallenges.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              ) : (
                <p>
                  Physical Challenges:{" "}
                  {contact?.PhysicalChallenges ? contact?.PhysicalChallenges : "None"}
                </p>
              )}
            </Col>
          )}
        </Row>

        <Row>
          <Col md={12}>
            {update ? (
              <Form.Group controlId='shortTermGoal'>
                <Form.Label>Short Term Goal</Form.Label>
                <Form.Control
                  {...register("shortTermGoal")}
                  type='text'
                  as='textarea'
                  placeholder='Enter short term goal'
                />
                {errors.shortTermGoal && (
                  <Form.Control.Feedback type='invalid'>
                    {errors.shortTermGoal.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            ) : (
              <p>Short Term Goal: {contact?.ShortTermGoal ? contact?.ShortTermGoal : "None"}</p>
            )}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {update ? (
              <Form.Group controlId='longTermGoal'>
                <Form.Label>Long Term Goal</Form.Label>
                <Form.Control
                  {...register("longTermGoal")}
                  type='text'
                  as='textarea'
                  placeholder='Enter long term goal'
                />
                {errors.longTermGoal && (
                  <Form.Control.Feedback type='invalid'>
                    {errors.longTermGoal.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            ) : (
              <p>Long Term Goal: {contact?.LongTermGoal ? contact?.LongTermGoal : "None"}</p>
            )}
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            {update ? (
              <Form.Group controlId='passion'>
                <Form.Label>Passion</Form.Label>
                <Form.Control
                  {...register("passion")}
                  type='text'
                  as='textarea'
                  placeholder='Enter passion'
                />
                {errors.passion && (
                  <Form.Control.Feedback type='invalid'>
                    {errors.passion.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            ) : (
              <p>Passion: {contact?.Passion ? contact?.Passion : "None"}</p>
            )}
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            {update ? (
              <Form.Group controlId='workPreference'>
                <Form.Label>How would you like to work</Form.Label>
                <Form.Control
                  {...register("workPreference")}
                  type='text'
                  placeholder='Enter work preference'
                />
                {errors.workPreference && (
                  <Form.Control.Feedback type='invalid'>
                    {errors.workPreference.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            ) : (
              <p>
                How would you like to work:{" "}
                {contact?.WorkPreference ? contact?.WorkPreference : "None"}
              </p>
            )}
          </Col>
          <Col md={4}>
            {update ? (
              <Form.Group controlId='expectedSalary'>
                <Form.Label>Expected Salary</Form.Label>
                <Form.Control
                  {...register("expectedSalary", {
                    onChange: (e) => {
                      let { value } = e.target
                      e.target.value = normalizeNumber(value)
                    },
                  })}
                  placeholder='Enter expected salary'
                />
                {errors.expectedSalary && (
                  <Form.Control.Feedback type='invalid'>
                    {errors.expectedSalary.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            ) : (
              <p>Expected Salary: {contact?.ExpectedSalary ? contact?.ExpectedSalary : "None"}</p>
            )}
          </Col>
        </Row>
      </Section>
    </Form>
  )
}

export default AdditionalForm
