import React, { useRef } from "react";
import DashboardView from "./style";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useFetch } from "../../../Hooks/useFetch";
import LeftColumn from "./LeftColumn";
import ContactForm from "../ContactForm";
import Loading from "../../Reuseable/RuLoading";
import jwtDecode from "jwt-decode";

const Dashboard = () => {

  const leftColRef = useRef(null)

  const onSuccess = (data) => {
    if (!data.status) return
    leftColRef.current?.scrollTo(0, 0)
  }

  const { data: contacts, isFetching, isLoading } = useFetch("contacts", "/2-resource/key", onSuccess, () => { }, false, true)
 
  return (

    <DashboardView>
      {isLoading ? <div className="pt-5"><Loading /></div> : <Container fluid>

        <Row>
          <LeftColumn contacts={contacts} isContactsFetching={isFetching} leftColRef={leftColRef} />
          <RightColumn contacts={contacts} />
        </Row>
      </Container>}

    </DashboardView>

  );
}

export default Dashboard


const RightColumn = ({ contacts }) => {

  const activeContact = useSelector((state) => state.Data.activeContact);

  return <Col className="right_column" md={9}>
    {!activeContact?.Name ?
      <DashboardContent />
      :
      <ContactForm contacts={contacts} />
    }
  </Col>
}

const DashboardContent = () => {

  const userName = localStorage.getItem("token") ? jwtDecode(localStorage.getItem("token"))?.username : ""
  const userRole = localStorage.getItem("token") ? jwtDecode(localStorage.getItem("token"))?.userRole : ""
  const userId = localStorage.getItem("token") ? jwtDecode(localStorage.getItem("token"))?.userId : ""
  
  const {data: dashboardDataRes, isLoading} = useFetch("dashboard", `/3-resource/${userRole}?emailId=${userId}`, () => {}, () => {}, false, true)

  const dashboardData = dashboardDataRes?.data ?? {}

  const isObjectEmpty = (data) => {
    return (data && Object.keys(data).length === 0) || data === undefined
  } 
  
  return <div className="dashboard_content">
    <h1>Hi {userName}</h1>

    <Row className="mt-3">
      {console.log("userRole-->", userRole)}
      {isLoading && userRole !== "Admin" && <Loading />}

    {!isObjectEmpty(dashboardData) && Object.keys(dashboardData).map((key, index) => {
      return <Col md={4} key={index + key}>
      <Card className="mb-3">
        <Card.Body>
          <Card.Title className="d-flex justify-content-between">{key} <span>{dashboardData[key]}</span></Card.Title>
        </Card.Body>
      </Card>
    </Col>
    })}

    </Row>
  </div>
}