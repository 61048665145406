import React from "react"
import { Route, Routes } from "react-router-dom"
import ProtectedRoute from "../Auth/protectedRoute"
import Layout from "../Components/Pages/Layout/Main"
import Login from "../Components/Pages/Login"
import PageNotFound from "../Components/Pages/PageNotFound"
import Dashboard from "../Components/Pages/Dashboard"

const UIRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<Login />} />
      <Route path='login' element={<Login />} />
      <Route element={<ProtectedRoute />}>
        <Route path='home' element={<Layout />}>
          <Route path='dashboard' element={<Dashboard />} />
        </Route>
      </Route>

      <Route path='*' element={<PageNotFound />}></Route>
    </Routes>
  )
}

export default UIRoutes
