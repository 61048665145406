import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  :root {
    --primary-color: red;
    --grey-color: #dee2e6;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .btn {
    border-radius: 0px;
  }
  .btn-primary {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    &:hover, &:focus, &:active {
      background-color: var(--primary-color) !important;
      border-color: var(--primary-color) !important;
    }
    &:disabled {
      background: var(--primary-color) !important;
      border-color: var(--primary-color) !important;
      opacity: 0.5;
    }
    &:hover {
      opacity: 0.8;
    }
  }

  /* Contact Form Styles */
.col-md-6, .col-md-8, .col-md-12, .col-md-4, .col-md-3 {
  margin-bottom: .5rem;
}
.invalid-feedback {
    display: block;
    font-size: 0.8rem;
    color: red;
}
.delete_icon {
    color: red;
    margin-right: 0.5rem;
}
.save_button {
    padding: 4px 8px;
}
.card.section {
    padding: .5rem;
    border-radius: 0px;
}
.card.section {
    .section_title {
        padding-bottom: 0.5rem;
        border-bottom: 1px solid var(--grey-color);
    }
}
input:focus, textarea:focus {
    outline: none;
    box-shadow: none !important;
}
textarea {
  resize: none;
}
.photo_input_area {
    display: flex;
    justify-content: start;
    align-items: center;
}
.image_upload_input {
    display: none;
}
.preview_box {
    border: 1px solid var(--grey-color);
    border-radius: 0px;
    width: 150px;
    height: 150px;
}
.preview_box.error {
    border: 1px solid red;
}
.no_img_box {
  border: 1px solid var(--grey-color);
  border-radius: 0px;
  width: 150px;
  height: 150px;
}
.update_image {
    position: absolute;
    bottom: 0;
    left: 3px;
    /* height: 50px; */
    translate: 0px 15px;
}
label.image_label_update{ 
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
}
.no_image_label {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.image_label {
    cursor: pointer;
    height: 100%;
    padding: 1px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .optional {
        font-size: 0.8rem;
    }
}
.image_preview {
    width: 140px;
    height: 140px;
    object-fit: cover;
}
.form-check-input[type=checkbox] {
  &:checked {
    border-color: var(--primary-color);
  background-color: var(--primary-color);
  }
}
.remove_button_area {
    display: flex;
    justify-content: center;
    align-items: end;
    height: 100%;
    translate: 0px -0.5rem;
}
.modal-content {
    border-radius: 0px;
}
.modal-body {
  .section.card {
    border: none;
  }
}
  
`;
