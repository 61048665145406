import { client } from "../../App"
import { ActionTypes } from "./actions"

const userLogin = (response) => {
	localStorage.setItem("isLoggedIn", response.isLoggedIn)
	localStorage.setItem("token", response.authToken)
	localStorage.setItem("user", response.user)
	return {
		type: ActionTypes.LOGIN,
		payload: response,
	}
}

const userLogout = () => {
	localStorage.removeItem("isLoggedIn")
	localStorage.removeItem("token")
	localStorage.removeItem("user")
	localStorage.removeItem("isFirstTimeLoggin")
	client.clear()
	return {
		type: ActionTypes.LOGOUT,
		payload: {
			isLoggedIn: false,
			authToken: "",
			user: "",
		},
	}
}

const setShowNote = (response) => {
	return {
		type: ActionTypes.SHOW_NOTE,
		payload: response,
	}
}

const userSessionTimeout = (response) => {
	return {
		type: ActionTypes.SET_SESSION_TIMEOUT,
		payload: response,
	}
}

export { userLogin, userLogout, setShowNote, userSessionTimeout }
