import Styled from "styled-components";

const HeaderWrap = Styled.div`
            .navbar {
                font-weight: 600;
                padding: 0px;
                /* height: 50px; */
            }
            .navbar-brand {
                padding: 2px;
            }
            .z-index-navbar {
                z-index: 100;
            }
            li {
                list-style: none;
            }
            img.header_logo {
                object-fit: cover;
                height: 50px;
                cursor: pointer;
            }
            .profile {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .blinking-dot {
                display: inline-block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: red;
                animation: blink 1s infinite;
            }
            @keyframes blink {
                0% {
                    opacity: 1;
                }
                50% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }
            .menu-display {
                width: 50px;
                display: flex;
                flex-direction: column;
                background: inherit;
                border-radius: 5px;
                padding-top: 10px;
                /* position: absolute;
                right: 0px;
                top: 10px; */
            }
            .menu {
                background-color: white !important;
            }
            .nav-link:focus {
            color: var(--primary-color);
            }
            .hide-menu {
            visibility: hidden;
            }
            .menu-item {
            cursor: pointer;
            padding: 6px;
            }
            @media (max-width: 767px) {
            .hide-menu {
                display: block;
                visibility: visible;
            }
            }
            .navbar-brand {
            font: normal normal medium 24px/35px;
            }
            .profileLetter {
                width: 45px;
                height: 45px;
                line-height: 45px;
                border-radius: 50%;
                background-color: #E4E4E4;
                color: black;
                opacity: .9;
                white-space: nowrap;
                text-align: center;
                align-self: center;
                font-size: 2.4em;
                font-weight: 500;
            }
            .navbar-toggler:focus {
                text-decoration: none; 
                outline: none; 
                box-shadow: 0 0 0 0; 
            }
            .log {
                position: absolute;
                top: 0;
                padding: 2px 0 0 0;
                right:10px;
            }.navbar-item{
                display: flex;
                width: 100%;
            }
            .role{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                text-align: left;
                padding-top: 10px;
                gap: 10px;
               
                p{
                    font-size: 12px;
                    padding: 2px;
                    line-height: 5px;
                }
                button{
                    background: red;
                    border: none;
                    color: white;
                    padding: 5px;
                    margin-bottom: 10px;
                }
                @media (max-width:450px){
                    flex-wrap: wrap;
               } 
            }
            .profile{   
                right:80px;
            }
            .pos {
                position: relative;
                top: 2px;
                z-index: 2;
            }
            .dropdown-toggle::after {
                content: none;
            }
            .infoworks {
                font: normal normal normal 20px/24px Montserrat;
            }
            .accountblack {
                font: normal normal normal 20px/24px Montserrat;
            }
            .nav-link.active {
                padding-bottom: 13px;
                border-bottom: 3px solid var(--primary-color);
                border-radius: 1px;
                transition: 0.1s easeinout;
                margin-bottom: -13px;
            }
            .modal-header {
                justify-content: center !important;
                align-self: center;
            }
            .modal-content {
                width: 50% !important;
                    margin-left:10%;
                    position: relative; 
                    top:50px;
            }
            .close_button {
                width: 100px !important;
            }
            .log_out_button {
                width: 100px !important;
                height: 38px !important;
                background: var(--primary-color);
                border: none;
                color: white;
            }
            .notification {
                 font: normal normal 20px/24px Montserrat;
                 border-bottom: 1px solid black;
                 margin-bottom: 8px;
            }
            .notification-message {
                font: normal normal 18px/20px Montserrat;;
            }
            .dropdown-menu.show {
                max-height: 600px !important;
                overflow: auto;
            }
            a, a:hover, a:visited, a:active {
                color: black;
                text-decoration: none;
            }
            .form-control {
                &:focus {
                    box-shadow: inherit !important;
                }
            }
            .imgfirstLetr {
                display: inline-block;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                color: #fff;
                box-shadow: inset 0px 3px 6px #ffffff30, 0px 3px 6px #00000029;
                font-size: 18px;
                padding: 6px 6px;
                margin: 0 auto;
                text-align: center;
            }

            @media (max-width:500px){
                .bidleadicon{
                    padding-bottom: 16px;
                }
            } 
            @media (max-width:450px){
                .bid-logo{
                    padding-bottom:20px;
                }
            } 

            @media(max-width: 766px) {
                .modal-content {
                    display: block;
                    width: 80% !important;
                    margin-left:10%;
                    position: relative; 
                    justify-content: center !important;
                    align-self: center;
                    top:90px;
                    }
            }

            @media (max-width:767px) {
                .log {
                    position: relative;
                }
                .navbar-nav {
                    float: right ;
                }
                .navbar-collapse {
                    display: none;
                  }
                  .navbar-toggler {
                    display: none;
                  }
                .navbar-collapse {
                    background-color: rgba(41, 40, 120,0.4);
                    width: 200px;
                  }
                  .modal-content {
                    display: block;
                    position: relative;
                    justify-content: center !important;
                    align-self: center;
                }
                  .container-fluid {
                    padding: 0;
                  }
                  .profile{
                        display: none;
                  }
            }

            @media (min-width: 992px) {
            .navbar {
                .navbar-menu-wrapper {
                .topNavListing.topNavListing-right {
                    margin-left: 20px;
                }
                }
            }
            }

            @media (max-width: 576px) {
                .five-min-timer-note {
                    font-size: 0.8rem;
                    padding-top: calc(60px + 5px);
                    padding-left: 10px;
                    padding-right: 10px;
                    padding-bottom: 5px;
            }
            }
`;

export default HeaderWrap;
