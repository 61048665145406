import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Col } from "react-bootstrap";
import { userLogin } from "../../../redux/Auth/actionCreator";
import { api } from '../../../Services';
import { Validators } from "../../../Utilities/validator";
import { Link, useNavigate } from "react-router-dom";
import { Forms } from "../../Reuseable/RuForms";
import jwtDecode from "jwt-decode";

const LoginForm = () => {
  const [invalidUser, setInvalidUser] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [changeInForm, setChangeInForm] = useState(false);
  let navigate = useNavigate()
  const layout = useSelector((state) => state.Auth.layout)

  const { login } = layout

  const SubmitLogin = async (form, valid) => {
    setInvalidUser(null);
    if (valid) {
      setLoading(true);
      form.password = form.password.trim();
      const obj = {
        emailId: form.email,
        password: form.password,
      }
      try {
        const response = await api.post('/1-resource', obj, {
        })
        console.log("[SubmitLogin] response-->", response);
        if (response.status) {
          let result = {
            isLoggedIn: true,
            authToken: response.data.authorizationToken,
            user: response?.data?.authorizationToken ? jwtDecode(response.data.authorizationToken).username : "Tester",
          }
          setSubmitted(true);
          dispatch(userLogin(result));
          setLoading(false);
          navigate("/home/dashboard")
        }
      } catch (error) {
        setInvalidUser(error.response.data.message);
        setLoading(false);
      }
    }

  };

  return (<>
    <div className="loginFormScreens my-3">
      {login.logo.show && <div className="text-center"><img className="logoimg" src={login.logo.src} alt="logo" /></div>}
      <h5 className="head fw-bold fs-5 mb-0 py-3 text-center login_heading">{login?.loginTitle1} <br /> {login?.loginTitle2}</h5>
      {invalidUser ? <Alert className="m-2 p-1 text-center" variant="danger">{invalidUser}</Alert> : <></>}

      <Forms title={""} formArr={[
        {
          label: "Email",
          name: "email",
          type: "text",
          placeholder: "Enter your email ID or phone number",
          validators: [
            { check: Validators.required, message: "This Field is required" },
            // { check: Validators.email, message: "Email is not Valid" }
          ],
          error: false,
          errormsg: "",
          value: "",
          show: true
        },
        {
          label: "Password",
          name: "password",
          type: "password",
          placeholder: "Enter your Password",
          validators: [
            { check: Validators.required, message: "This Field is required" },
            { check: Validators.password, message: "Password is not Valid" }
          ],
          error: false,
          errormsg: "",
          value: "",
          show: true
        }
      ]}
        submitBtn={"Login"}
        formlinks={
          login.forgotPassword.show && <Link to={login.forgotPassword.link} className="forgotLink">Forgot password?</Link>
        }
        onSubmit={SubmitLogin} loading={loading} submitted={submitted} changeInForm={changeInForm} setChangeInForm={setChangeInForm}
      />

      {login.signUp.show ? (<p className="account text-center">
        <Col>
          <div className="signup" ><p> Don't have an account? <Link to={login.signUp.link}>Signup</Link></p></div>
        </Col>
      </p>) : <></>}
    </div>
  </>
  )
}

export default LoginForm;