import React, { useEffect } from "react"
import { Col, Form, Row, Spinner } from "react-bootstrap"
import { Section } from "./Section"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import SelectBox from "../../Reuseable/RUSelectBox"
import { Box } from "@mui/material"
import {
  genderOptions,
  heightOptions,
  maritialStatusOptions,
  religionOptions,
  skinColorOptions,
  weightOptions,
} from "./AllSelectOptions"
import { usePatch } from "../../../Hooks/usePatch"
import { client } from "../../../App"
import { getContrastingColor, normalizeNumber } from "../../../Utilities/commonFn"
import { useMutate } from "../../../Hooks/useMutate"
import ImageCropper from "../../Reuseable/RuImageCropper"
import UIModal from "../../Reuseable/RuModal"
import { setAlertMessage } from "../../../redux/Data/actionCreator"
import { useDispatch } from "react-redux"
import DescriptionIcon from "@mui/icons-material/Description"

const formatOptionLabel = ({ label, value }) => {
  return (
    <div style={{ display: "flex" }}>
      <div style={{ background: value, color: getContrastingColor(value) }}>{label}</div>
    </div>
  )
}

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  phone: yup.string().nullable(),
  email: yup.string().email("Enter a valid email").nullable(),
  dob: yup.string().nullable(),
  gender: yup
    .object()
    .shape({
      value: yup.string().required(),
      label: yup.string().required(),
    })
    .nullable(),
  maritalStatus: yup
    .object()
    .shape({
      value: yup.string().required(),
      label: yup.string().required(),
    })
    .nullable(),
})

const PersonalForm = ({
  contact,
  type,
  isImgLoading,
  handleEditForm,
  handleCloseForm,
  selectedImage,
  setSelectedImage,
}) => {
  const [edit, setEdit] = React.useState(type === "edit" ? true : false)
  const formName = "personal"
  const [cropImage, setCropImage] = React.useState(false)
  const [imageError, setImageError] = React.useState(false)
  const [imageUpdated, setImageUpdated] = React.useState(false)
  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    control,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: contact?.Name ?? null,
      phone: contact?.PhoneNo ?? null,
      email: contact?.EmailId ?? null,
      dob: contact?.Dob ?? null,
      gender: contact?.Gender ?? null,
      maritalStatus: contact?.MaritalStatus ?? null,
      religion: contact?.Religion ?? null,
      skinColor: contact?.SkinColor ?? null,
      weight: contact?.Weight ?? null,
      height: contact?.Height ?? null,
    },
  })

  useEffect(() => {
    reset({
      name: contact?.Name ?? null,
      phone: contact?.PhoneNo ?? null,
      email: contact?.EmailId ?? null,
      dob: contact?.Dob ?? null,
      gender: contact?.Gender ?? null,
      maritalStatus: contact?.MaritalStatus ?? null,
      religion: contact?.Religion ?? null,
      skinColor: contact?.SkinColor ?? null,
      weight: contact?.Weight ?? null,
      height: contact?.Height ?? null,
    })
  }, [contact, reset])

  const handleEditShow = () => {
    reset()
    handleEditForm(formName)
    setEdit(true)
  }

  const handleEditClose = () => {
    handleCloseForm(formName)
    reset()
  }

  const onSuccess = async (data) => {
    if (!data.status) return
    await client.setQueryData(["contacts"], (oldData) => {
      return {
        status: data.status,
        data: oldData?.data?.map((contact) => {
          if (contact?.SK === data?.data?.SK) {
            return { ...contact, ...data?.data }
          }
          return contact
        }),
      }
    })
    dispatch(
      setAlertMessage({
        type: "success",
        message: data?.message,
      })
    )
    handleCloseForm(formName)
  }

  const onImgUploadSuccess = (data) => {
    if (!data.status) return
    dispatch(
      setAlertMessage({
        type: "success",
        message: data?.message,
      })
    )
    setSelectedImage({
      codeValue: null,
    })
  }

  const onImgUploadError = (error) => {
    console.log("onImgUploadError  error -->", error)
    dispatch(
      setAlertMessage({
        type: "error",
        message: error?.message,
      })
    )
  }

  const { mutate: uploadImg } = useMutate(
    "upload-image",
    `/2-resource/contact-img/${contact?.SK}`,
    onImgUploadSuccess,
    onImgUploadError
  )

  const clearImage = (e) => {
    return (e.target.value = null)
  }

  const handleImageUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        const base64Data = reader.result.split(",")[1]
        const fileType = file.type
        const fileSize = file.size
        const fileName = file.name

        if (fileSize > 2000000) {
          // the Image should be less than 2 MB
          dispatch(
            setAlertMessage({
              type: "error",
              message: "File size should be less than 2 MB",
            })
          )
          return
        }

        setSelectedImage({
          URL: reader.result,
          ID: contact.SK,
          fileName,
          fileType,
          codeValue: base64Data,
        })
        setImageUpdated(true)
        setCropImage(true)
      }
      reader.readAsDataURL(file)
    }
  }

  const { mutate, isLoading } = usePatch(
    "update-contact",
    `/2-resource/${contact?.SK}`,
    onSuccess,
    () => {}
  )

  const onSubmit = (e) => {
    if (!getValues().phone && !getValues().email) {
      dispatch(
        setAlertMessage({
          type: "error",
          message: "Phone or Email is required",
        })
      )
      return
    }

    if (!selectedImage?.URL) {
      setImageError(true)
      return dispatch(
        setAlertMessage({
          type: "error",
          message: "Image is Madatory",
        })
      )
    }
    setImageError(false)
    let data = {
      Name: e?.name,
      PhoneNo: e?.phone,
      EmailId: e?.email,
      Dob: e?.dob,
      Gender: e?.gender,
      MaritalStatus: e?.maritalStatus,
      Religion: e?.religion,
      SkinColor: e?.skinColor,
      Weight: e?.weight,
      Height: e?.height,
    }
    selectedImage?.codeValue &&
      uploadImg({
        codeValue: selectedImage?.codeValue,
        fileName: selectedImage?.fileName,
        fileType: selectedImage?.fileType,
        ID: contact?.SK,
      })
    mutate(data)
  }

  const update = edit && type === "edit" ? true : false

  const contactImg = selectedImage?.URL !== "null" ? selectedImage?.URL : null

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Section
        title='Personal'
        onShowEdit={handleEditShow}
        onCloseEdit={handleEditClose}
        isLoading={isLoading}
        disableUpdate={isDirty || imageUpdated}
        type={type}
        edit={edit}
        isApproved={contact?.Approval_Status === "Approved"}
      >
        <Row>
          <UIModal show={cropImage} onHide={() => setCropImage(false)}>
            <ImageCropper
              image={contactImg}
              contact={contact}
              setSelectedImage={setSelectedImage}
              setCropImage={setCropImage}
            />
          </UIModal>

          <Col md={4}>
            <div className='photo_input_area'>
              {contactImg && update ? (
                <>
                  <Box mt={2} className='position-relative'>
                    {contactImg && (
                      <img
                        key={contactImg}
                        src={contactImg}
                        alt='update-preview'
                        className='image_preview'
                      />
                    )}

                    <Box className='update_image'>
                      <input
                        accept='image/*'
                        id='image-upload'
                        type='file'
                        className='image_upload_input'
                        onClick={clearImage}
                        onChange={handleImageUpload}
                      />
                      <label className='image_label_update' htmlFor='image-upload'>
                        <div>Update Image</div>
                      </label>
                    </Box>
                  </Box>
                </>
              ) : (
                <Box className='no_img_box'>
                  <label className='no_image_label'>
                    {contactImg ? (
                      isImgLoading ? (
                        <Spinner animation='border' size='sm' />
                      ) : (
                        <img
                          key={contactImg}
                          src={contactImg}
                          alt='preview'
                          className='image_preview'
                        />
                      )
                    ) : update ? (
                      <Box className={`preview_box ${imageError ? "error" : ""}`}>
                        <input
                          accept='image/*'
                          id='image-upload'
                          type='file'
                          className='image_upload_input'
                          onChange={handleImageUpload}
                        />

                        <label className='image_label' htmlFor='image-upload'>
                          <DescriptionIcon fontSize='medium' />
                          <div>Add Image</div>
                        </label>
                      </Box>
                    ) : (
                      <div>
                        {isImgLoading ? <Spinner animation='border' size='sm' /> : "No Photo"}
                      </div>
                    )}
                  </label>
                </Box>
              )}
            </div>
          </Col>

          <Col md={4}>
            <div className='d-flex flex-column h-100 justify-content-around'>
              <div>
                {update ? (
                  <Form.Group controlId='name'>
                    <Form.Label>Name</Form.Label>
                    <Form.Control {...register("name")} type='text' placeholder='Enter name' />
                    {errors.name && (
                      <Form.Control.Feedback type='invalid'>
                        {errors.name.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                ) : (
                  <p>Name : {contact?.Name ? contact?.Name : "None"}</p>
                )}
              </div>

              <div>
                {update ? (
                  <Form.Group controlId='email'>
                    <Form.Label>Email ID</Form.Label>
                    <Form.Control
                      {...register("email")}
                      type='email'
                      placeholder='Enter email ID'
                    />
                    {errors.email && (
                      <Form.Control.Feedback type='invalid'>
                        {errors.email.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                ) : (
                  <p>Email ID : {contact?.EmailId ? contact?.EmailId : "None"}</p>
                )}
              </div>
            </div>
          </Col>

          <Col md={4}>
            <div className='d-flex flex-column h-100 justify-content-around'>
              <div className='mb-1'>
                {update ? (
                  <Form.Group controlId='phone'>
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      maxLength={14}
                      {...register("phone", {
                        onChange: (e) => {
                          const { value } = e.target
                          e.target.value = normalizeNumber(value)
                        },
                      })}
                      type='text'
                      placeholder='Enter phone number'
                    />
                    {errors.phone && (
                      <Form.Control.Feedback type='invalid'>
                        {errors.phone.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                ) : (
                  <p>Phone : {contact?.PhoneNo ? contact?.PhoneNo : "None"}</p>
                )}
              </div>

              <div>
                {update ? (
                  <Form.Group controlId='dob'>
                    <Form.Label>DOB</Form.Label>
                    <Form.Control
                      {...register("dob")}
                      type='date'
                      placeholder='Enter date of birth'
                    />
                    {errors.dob && (
                      <Form.Control.Feedback type='invalid'>
                        {errors.dob.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                ) : (
                  <p>DOB : {contact?.Dob ? contact?.Dob : "None"}</p>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            {update ? (
              <Form.Group controlId='gender'>
                <Form.Label>Gender</Form.Label>
                <Controller
                  control={control}
                  ref={register}
                  name='gender'
                  render={({ field }) => <SelectBox options={genderOptions} {...field} />}
                />
                {errors.gender && (
                  <Form.Control.Feedback type='invalid'>
                    {errors.gender.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            ) : (
              <p>Gender : {contact?.Gender?.label ? contact?.Gender?.label : "None"}</p>
            )}
          </Col>

          <Col md={4}>
            {update ? (
              <Form.Group controlId='maritalStatus'>
                <Form.Label>Marital Status</Form.Label>
                <Controller
                  ref={register}
                  name='maritalStatus'
                  control={control}
                  render={({ field }) => <SelectBox options={maritialStatusOptions} {...field} />}
                />
                {errors.maritalStatus && (
                  <Form.Control.Feedback type='invalid'>
                    {errors.maritalStatus.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            ) : (
              <p>
                Marital Status :{" "}
                {contact?.MaritalStatus?.label ? contact?.MaritalStatus?.label : "None"}
              </p>
            )}
          </Col>

          <Col md={4}>
            {update ? (
              <Form.Group controlId='religion'>
                <Form.Label>Religion</Form.Label>

                <Controller
                  control={control}
                  ref={register}
                  name='religion'
                  render={({ field }) => <SelectBox options={religionOptions} {...field} />}
                />

                {errors.religion && (
                  <Form.Control.Feedback type='invalid'>
                    {errors.religion.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            ) : (
              <p>Religion : {contact?.Religion?.label ? contact?.Religion?.label : "None"}</p>
            )}
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            {update ? (
              <Form.Group controlId='skinColor'>
                <Form.Label>Skin Color</Form.Label>
                <Controller
                  control={control}
                  ref={register}
                  name='skinColor'
                  render={({ field }) => (
                    <SelectBox
                      formatOptionLabel={formatOptionLabel}
                      options={skinColorOptions}
                      {...field}
                    />
                  )}
                />
                {errors.skinColor && (
                  <Form.Control.Feedback type='invalid'>
                    {errors.skinColor.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            ) : (
              <p>Skin Color: {contact?.SkinColor?.label ? contact?.SkinColor?.label : "None"}</p>
            )}
          </Col>
          <Col md={4}>
            {update ? (
              <Form.Group controlId='weight'>
                <Form.Label>Weight (kgs)</Form.Label>
                <Controller
                  control={control}
                  ref={register}
                  name='weight'
                  render={({ field }) => <SelectBox options={weightOptions} {...field} />}
                />

                {errors.weight && (
                  <Form.Control.Feedback type='invalid'>
                    {errors.weight.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            ) : (
              <p>Weight : {contact?.Weight?.label ? `${contact.Weight?.label} kgs` : "None"} </p>
            )}
          </Col>
          <Col md={4}>
            {update ? (
              <Form.Group controlId='height'>
                <Form.Label>Height (feet)</Form.Label>
                <Controller
                  control={control}
                  ref={register}
                  name='height'
                  render={({ field }) => <SelectBox options={heightOptions} {...field} />}
                />

                {errors.height && (
                  <Form.Control.Feedback type='invalid'>
                    {errors.height.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            ) : (
              <p>Height : {contact?.Height?.label ? `${contact?.Height?.label} feet` : "None"}</p>
            )}
          </Col>
        </Row>
      </Section>
    </Form>
  )
}

export default PersonalForm
