import Styled from "styled-components";

const LayoutWrap = Styled.div`
        .main-panel{
            overflow-y: auto;
            overflow-x: hidden;
            height: calc(100vh - 50px);
            background: white !important;
        }
        .main-panel.topmenu {
            margin-left: 0px;
        }
        .main-panel {
            display: flex;
            flex-direction: column;
            min-width: 0;
            z-index: 11;
            width: 100%;
            flex-grow: 1;
          }
        .page-body-wrapper {
            height: auto;
            overflow: hidden;
            overflow-y: hidden;
            padding-top: 50px;
            display: flex;
            flex-direction: row;
            padding-left: 0;
            padding-right: 0;
        }
        .toggle {
            padding-top: 40px;
            padding-left: 5px;
            padding-right: 0;
            background: #fff;
            border: none;
        }
        .filter_menu_icon {
            border: 1px solid inherit;
        }
        
        @media (max-width: 575px) {
            .main-panel {
                margin-left: 0;
            }
            .mobSideBar .main-panel {
                margin-left: 0;
            }
            .menu {
                width: 15px;
                height: 15px;
            }
            .sidebar {
                width: 0;
            }
            .toggle {
                padding-top: 40px;
                padding-left: 1px;
            }
            .mobSideBar .sidebar.sidebarMain {
                width: 0;
            }
            .mobSideBar .sidebarMainLinks {
                width: 160px;
            }
            .mobSideBar .sidebar.sidebarMain {

                width: 160px;
                z-index: 111;
            }
            .mobSideBar .nav-link span.dsktopView {
                display: inline-block;
            }
            .sidebarMain .serchFieldMain
                {
                    display: none;
                }
                .mobSideBar .sidebarMain .serchFieldMain {
                    display: inline-block;
                }
        }
`;

export default LayoutWrap;
