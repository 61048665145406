import { ActionTypes } from './actions';

const setActiveContact = (payload) => {
    return {
        type: ActionTypes.SET_ACTIVE_CONTACT,
        payload
    }
}

const setAlertMessage = (payload) => {
    return {
        type: ActionTypes.SET_ALERT_MESSAGE,
        payload
    }
}

export { setActiveContact, setAlertMessage };