import Styled from 'styled-components';

const DashboardView = Styled.div`

.left_column {
   outline: none;
   position: sticky;
   top: 0;
   translate: 0px .5rem;
   padding: .5rem;
   border-radius: .5rem;
   margin-bottom: 20px;
   border-radius: 0px;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
   height: calc(100vh - 70px);
   overflow-y: auto;
}
.right_column {
   padding: 0 .5rem .5rem .5rem;
   translate: 0px .5rem;
   border-radius: 5px;
   margin-bottom: 20px;
}
.dashboard_content {
   padding: 1rem;
   background-color: #dee2e6;
   border-radius: 0px;
}
.card {
   margin-bottom: .5rem;
}
.add_new_contact_card {
   padding: .5rem;
   background-color: #dee2e6;
   border-radius: 0px;
   margin-bottom: 0rem;
   border: none;
   .card-body {
      padding: 0rem;
   }
}
.add_new {
   display: flex;
   justify-content: start;
   align-items: center;
   font-size: 1.2rem;
   gap: .6rem;
   border-radius: .5rem;
   padding: .5rem;
   cursor: pointer;
   position: sticky;
   top: 0;
   z-index: 999;
   border-radius: 0px;
   background-color: #dee2e6;
   &:before {
      content: "";
      position: absolute;
      top: -10px;
      left: 0;
      width: 100%;
      height: 10px;
      background-color: white;
   }
}
.search_bar {
   margin: .5rem 0rem;
}
.add_new_contact {
   text-align: end;
   &>div {
      margin-top: .2rem;
   }
   
   .done_icon {
      color: var(--primary-color);
   }
}
input[type="text"] {
   padding: 5px 10px;
   width: 100%;
   background: #ffffff;
}
fieldset {
   border-radius: 0px !important;
}
.tool_bar {
   display: flex;
   justify-content: end;
   margin-top: .2rem;
   .refresh_icon.loading {
      animation: spin 1s linear infinite;
   }
   @keyframes spin {
   0% {
      transform: rotate(0deg);
   }
   100% {
      transform: rotate(360deg);
   }
}
}

.contacts {
   margin-top: 0rem;  
}

@media (max-width: 767px) {
   .left_column {
      position: relative;
      height: auto;
   }
}

`;

export default DashboardView;


export const ContactCard = Styled.div`

.contact_card {
   border-radius: 0px;
      .contact_card_body {
         padding: .5rem .5rem .5rem 1rem;
         min-height: 80px;
         &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 5px;
            height: 100%;
            background-color: var(--primary-color);
         }
         &:after {
            content: ${(props) => props.status === 'Approved' ? "" : `"${props.status}"`};
            position: absolute;
            top: 0;
            right: 0;
            background-color: ${(props) => props.status === 'Draft' ? 'grey' : props.status === 'Ready For Approval' ? 'green' : props.status === 'Approved' ? 'green' : props.status === 'Declined' ? 'red' : 'transparent'};
            color: black;
            font-size: .8rem;
            font-weight: 500;
            padding: 0rem .1rem;
            margin: .1rem .1rem 0rem 0rem ;
         }
      }
}
.contact {
   display: flex;
   flex-direction: row;
   align-items: center;
   height: 100%;
   justify-content: space-between;
   .email_id {
      font-size: 0.85rem;
   }
   .phone_number {
      font-size: 0.85rem;
   }
   .delete_icon {
      display: none;
      color: red;
      padding: .3rem .5rem;
   }
   &:hover {
      .delete_icon {
         display: block;
      }
   }
}
.card-body:has(.contact) {
   &:hover {
      background-color: #f5f5f5;
   }
}
.card-body:has(.contact.active) {
      background-color: rgba(89, 166, 125, .5);
}
.card-body:has(.project_head) {
   background-color: #f5f5f5;
}

`