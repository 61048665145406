import React, { useEffect } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { Section } from "./Section"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { usePatch } from "../../../Hooks/usePatch"
import { client } from "../../../App"
import SelectBox from "../../Reuseable/RUSelectBox"
import { countryOptions } from "./AllSelectOptions"
import { normalizeNumber } from "../../../Utilities/commonFn"

// const aadharRegex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/
// const panCardRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/

const schema = yup.object().shape({
  currentAddress: yup.string().nullable(),
  permanentAddress: yup.string().nullable(),
  zipCode: yup.string().nullable(),
  cityOrTown: yup.string().nullable(),
  nationality: yup
    .object()
    .shape({
      value: yup.string().required(),
      label: yup.string().required(),
    })
    .required("Nationality is Required"),
  citizenship: yup
    .object()
    .shape({
      value: yup.string().nullable(),
      label: yup.string().nullable(),
    })
    .nullable(),
  aadharNo: yup.string().nullable(),
  panCardNo: yup.string().nullable(),
})

const LocationForm = ({ contact, type, handleEditForm, handleCloseForm }) => {
  const [edit, setEdit] = React.useState(type === "edit" ? true : false)
  const formName = "location"

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    control,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      currentAddress: contact?.CurrentAddress ?? null,
      permanentAddress: contact?.PermanentAddress ?? null,
      zipCode: contact?.ZipCode ?? null,
      cityOrTown: contact?.CityOrTown ?? null,
      nationality: contact?.Nationality ?? null,
      citizenship: contact?.Citizenship ?? null,
      aadharNo: contact?.AadharNo ?? null,
      panCardNo: contact?.PanCardNo ?? null,
    },
  })

  useEffect(() => {
    reset({
      currentAddress: contact?.CurrentAddress ?? null,
      permanentAddress: contact?.PermanentAddress ?? null,
      zipCode: contact?.ZipCode ?? null,
      cityOrTown: contact?.CityOrTown ?? null,
      nationality: contact?.Nationality ?? null,
      citizenship: contact?.Citizenship ?? null,
      aadharNo: contact?.AadharNo ?? null,
      panCardNo: contact?.PanCardNo ?? null,
    })
  }, [contact, reset])

  const isIndian = watch("nationality")?.value === "IN" ? true : false

  const handleEditShow = () => {
    reset()
    handleEditForm(formName)
    setEdit(true)
  }

  const handleEditClose = () => {
    handleCloseForm(formName)
    reset()
  }

  const onSuccess = async (data) => {
    if (!data.status) return
    await client.setQueryData(["contacts"], (oldData) => {
      return {
        status: data.status,
        data: oldData?.data?.map((contact) => {
          if (contact?.SK === data?.data?.SK) {
            return { ...contact, ...data?.data }
          }
          return contact
        }),
      }
    })
    handleCloseForm(formName)
  }

  const { mutate, isLoading } = usePatch(
    "update-contact",
    `/2-resource/${contact?.SK}`,
    onSuccess,
    () => {}
  )

  const onSubmit = (e) => {
    let data = {
      CurrentAddress: e?.currentAddress,
      PermanentAddress: e?.permanentAddress,
      ZipCode: e?.zipCode,
      CityOrTown: e?.cityOrTown,
      Nationality: e?.nationality,
      Citizenship: e?.citizenship,
      AadharNo: e?.aadharNo,
      PanCardNo: e?.panCardNo,
    }
    mutate(data)
  }

  const update = edit && type === "edit" ? true : false

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Section
        title='Location'
        onShowEdit={handleEditShow}
        onCloseEdit={handleEditClose}
        isLoading={isLoading}
        type={type}
        edit={edit}
        disableUpdate={isDirty}
        isApproved={contact?.Approval_Status === "Approved"}
      >
        <Row>
          <Col md={12}>
            {update ? (
              <Form.Group controlId='currentAddress'>
                <Form.Label>Current Address</Form.Label>
                <Form.Control
                  {...register("currentAddress")}
                  as='textarea'
                  type='text'
                  placeholder='Enter current address'
                />
                {errors.currentAddress && (
                  <Form.Control.Feedback type='invalid'>
                    {errors.currentAddress.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            ) : (
              <p>Current Address: {contact?.CurrentAddress ? contact?.CurrentAddress : "None"}</p>
            )}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {update ? (
              <Form.Group controlId='permanentAddress'>
                <Form.Label>Permanent Address</Form.Label>
                <Form.Control
                  {...register("permanentAddress")}
                  as='textarea'
                  type='text'
                  placeholder='Enter permanent address'
                />
                {errors.permanentAddress && (
                  <Form.Control.Feedback type='invalid'>
                    {errors.permanentAddress.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            ) : (
              <p>
                Permanent Address : {contact?.PermanentAddress ? contact?.PermanentAddress : "None"}
              </p>
            )}
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            {update ? (
              <Form.Group controlId='zipCode'>
                <Form.Label>Pin code</Form.Label>
                <Form.Control {...register("zipCode")} type='text' placeholder='Enter Zip code' />
                {errors.zipCode && (
                  <Form.Control.Feedback type='invalid'>
                    {errors.zipCode.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            ) : (
              <p>Pin code : {contact?.ZipCode ? contact?.ZipCode : "None"}</p>
            )}
          </Col>
          <Col md={4}>
            {update ? (
              <Form.Group controlId='cityOrTown'>
                <Form.Label>City or Town</Form.Label>
                <Form.Control
                  {...register("cityOrTown")}
                  type='text'
                  placeholder='Enter city or town'
                />
                {errors.cityOrTown && (
                  <Form.Control.Feedback type='invalid'>
                    {errors.cityOrTown.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            ) : (
              <p>City or Town : {contact?.CityOrTown ? contact?.CityOrTown : "None"}</p>
            )}
          </Col>

          <Col md={4}>
            {update ? (
              <Form.Group controlId='nationality'>
                <Form.Label>Nationality</Form.Label>

                <Controller
                  ref={register}
                  name='nationality'
                  control={control}
                  render={({ field }) => (
                    <SelectBox
                      placeholder='Enter Nationality'
                      {...field}
                      options={countryOptions}
                    />
                  )}
                />

                {errors.nationality && (
                  <Form.Control.Feedback type='invalid'>
                    {errors.nationality.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            ) : (
              <p>
                Nationality : {contact?.Nationality?.label ? contact?.Nationality?.label : "None"}
              </p>
            )}
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            {update ? (
              <Form.Group controlId='citizenship'>
                <Form.Label>Citizenship</Form.Label>

                <Controller
                  ref={register}
                  name='citizenship'
                  control={control}
                  render={({ field }) => (
                    <SelectBox
                      {...field}
                      placeholder='Enter citizenship'
                      options={countryOptions}
                    />
                  )}
                />
                {errors.citizenship && (
                  <Form.Control.Feedback type='invalid'>
                    {errors.citizenship.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            ) : (
              <p>
                Citizenship : {contact?.Citizenship?.label ? contact?.Citizenship?.label : "None"}
              </p>
            )}
          </Col>

          {(isIndian || contact?.Nationality?.value === "IN") && (
            <Col md={4}>
              {update ? (
                <Form.Group controlId='aadharNo'>
                  <Form.Label>Aadhar Number</Form.Label>
                  <Form.Control
                    {...register("aadharNo", {
                      onChange: (e) => {
                        const { value } = e.target
                        e.target.value = normalizeNumber(value)
                      },
                    })}
                    type='text'
                    placeholder='Enter aadhar number'
                  />
                  {errors.aadharNo && (
                    <Form.Control.Feedback type='invalid'>
                      {errors.aadharNo.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              ) : (
                <p>Aadhar Number : {contact?.AadharNo ? contact?.AadharNo : "None"}</p>
              )}
            </Col>
          )}

          {(isIndian || contact?.Nationality?.value === "IN") && (
            <Col md={4}>
              {update ? (
                <Form.Group controlId='panCardNo'>
                  <Form.Label>Pan Card ID</Form.Label>
                  <Form.Control
                    {...register("panCardNo")}
                    type='text'
                    placeholder='Enter pan card number'
                  />
                  {errors.panCardNo && (
                    <Form.Control.Feedback type='invalid'>
                      {errors.panCardNo.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              ) : (
                <p>Pan Card Number : {contact?.PanCardNo ? contact?.PanCardNo : "None"}</p>
              )}
            </Col>
          )}
        </Row>
      </Section>
    </Form>
  )
}

export default LocationForm
