import React, { useEffect } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { Section } from './Section'
import { usePatch } from '../../../Hooks/usePatch';
import { client } from '../../../App';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup'
import SelectBox from '../../Reuseable/RUSelectBox';
import { visibilityOptions } from './AllSelectOptions';

const schema = yup.object().shape({
    visibility: yup.object().shape({
        label: yup.string().required('Visibility is required'),
        value: yup.string().required('Visibility is required'),
    }).required("Visibility is required")
})

const SettingsForm = ({ contact, type, handleEditForm, handleCloseForm, }) => {

    const [edit, setEdit] = React.useState(type === 'edit' ? true : false);
    const formName = "settings"

    const { register, handleSubmit, formState: { errors, isDirty }, reset, control } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            visibility: contact?.Visibility ?? null,
        }
    });

    useEffect(() => {
        reset({
            visibility: contact?.Visibility ?? null,
        })
    }, [contact, reset])


    const handleEditShow = () => {
        reset()
        handleEditForm(formName)
        setEdit(true)
    }

    const handleEditClose = () => {
        handleCloseForm(formName)
        reset()
    }

    const onSuccess = async (data) => {
        if (!data.status) return
        await client.setQueryData(["contacts"], (oldData) => {
            return {
                status: data.status,
                data: oldData?.data?.map((contact) => {
                    if (contact?.SK === data?.data?.SK) {
                        return { ...contact, ...data?.data }
                    }
                    return contact
                })
            }
        }
        )
        handleCloseForm(formName)
    }

    const { mutate, isLoading } = usePatch("update-contact", `/2-resource/${contact?.SK}`, onSuccess, () => { })

    const onSubmit = (e) => {
        let data = {
            Visibility: e.visibility,
        }
        mutate(data)
    }

    const update = (edit && (type === "edit")) ? true : false


    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Section title="Settings"
                onShowEdit={handleEditShow}
                onCloseEdit={handleEditClose}
                isLoading={isLoading}
                disableUpdate={isDirty}
                type={type}
                edit={edit}
                isApproved={contact?.Approval_Status === "Approved"}>
                    
                <Row>
                    <Col md={6}>
                        {update ? <Form.Group controlId="visibility">
                            <Form.Label>Visibility</Form.Label>
                            <Controller
                                ref={register}
                                name="visibility"
                                control={control}
                                render={({ field }) => (<SelectBox {...field} options={visibilityOptions} />)}
                            />
                            {errors.visibility && <Form.Control.Feedback type="invalid">{errors.visibility?.message}</Form.Control.Feedback>}
                        </Form.Group> :
                            <p>Visibility: {contact?.Visibility?.label ? contact?.Visibility?.label : "None"}</p>}
                    </Col>
                </Row>


            </Section>
        </Form>
    )
}

export default SettingsForm